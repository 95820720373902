import axios from "axios";
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";
import { useMutation } from "react-query";

async function getIndividualByCpf({ cpf }) {
  const response = await axios.get(
    `${API}/individuals?cpf=${cpf}`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const individualsMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => getIndividualByCpf(e),
  );

  return {
    isSuccessIndividualByCpf: isSuccess,
    dataIndividualByCpf: data,
    isLoadingIndividualByCpf: isLoading,
    errorIndividualByCpfUseCase: error,
    individualByCpfMutation: mutate,
  }
}