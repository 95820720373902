import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Select, Typography } from 'antd';
import Flex from '../../../componentes/atoms/Flex';

const CashbackVoucher = ({ voucherEdit, setVoucherEdit, dataProviders }) => {
  if (!voucherEdit.cashback) {
    setVoucherEdit({
      ...voucherEdit,
      cashback: [{ providerId: "", percentage: "", duration: '' }],
    })
  }

  const handleChangeInput = (index, event) => {
    const values = [...voucherEdit.cashback];
    values[index][event.target.name] = event.target.value;
    setVoucherEdit({
      ...voucherEdit,
      cashback: values,
    });
  };

  const handleAddFields = () => {
    setVoucherEdit({
      ...voucherEdit,
      cashback: [
        ...voucherEdit.cashback,
        { providerId: "", percentage: "", duration: '' },
      ],
    });
  };

  const handleRemoveFields = (index) => {
    const values = [...voucherEdit.cashback];
    values.splice(index, 1);
    setVoucherEdit({
      ...voucherEdit,
      cashback: values,
    });
  };

  return (
    <Flex
      style={{
        gap: '20px',
        margin: '10px',
        display: 'table-row',
        border: 'solid 1px',
        width: '100%',
      }}>
      <Typography.Title level={3}>Cashback</Typography.Title>
      {(voucherEdit.cashback || []).map((inputField, index) => (
        <Flex
          key={index}
          style={{
            gap: '20px',
            margin: '10px',
            width: '100%',
          }}
        >
          <div style={{ width: '400px' }} >
            <Typography.Title level={5}>Provedor</Typography.Title>
            <Select
              style={{ width: '100%' }}
              value={inputField.providerId}
              name='providerId'
              onChange={(_, value) => handleChangeInput(index, {
                target: {
                  name: 'providerId',
                  value: value.key,
                },
              })
              }
            >
              {
                dataProviders.map(({id, name}) => {
                  const providerName = name.toUpperCase()
                  return (
                    <Select.Option key={id} value={id} placeholder={providerName}>
                      {providerName}
                    </Select.Option>)
                  },
                )
              }
            </Select>
          </div>
          <div style={{ width: '200px' }}>
            <Typography.Title level={5} placeholder="Cashback em porcentagem"
              >Porcentagem</Typography.Title>
            <Input
              placeholder="Cashback em porcentagem"
              name="percentage"
              value={inputField.percentage}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </div>
          <div style={{ width: '200px' }}>
            <Typography.Title
              level={5} 
              placeholder="Quantos meses de cashback"
            >Duração</Typography.Title>
            <Input
              placeholder="Quantos meses de cashback"
              name="duration"
              value={inputField.duration}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </div>
          <MinusCircleOutlined onClick={() => handleRemoveFields(index)} />
          <div>
          </div>
        </Flex>
      ))}
      <div>
      </div>

      <Button type="dashed" onClick={() => handleAddFields()} style={{ width: '100%', marginTop: '3%' }}>
        Adicionar Provedor
      </Button>
    </Flex>
  )
};

export default CashbackVoucher;
