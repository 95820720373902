const AccommodationMap = {
  shared:  'Compartilhado',
  private: 'Individual',
}

const AdherenceMap = {
  compulsory:  'Compulsório',
  loose: 'Livre Adesão',
}

const CopaymentMap = {
  partial:  'Parcial',
  without: 'Sem',
  with: 'Com',
  with_partial: "Parcial",
}

module.exports = {
  AccommodationMap,
  AdherenceMap,
  CopaymentMap,
}