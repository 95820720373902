import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function getProducts({ age, state }) {
  const response = await axios.get(
    `${API}/products?age=${age}&state=${state}`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const productsQuery = ({ age, state }) => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery(
    ["getProductsQuery", age, state],
    () => getProducts({ age, state }),
  );

  return {
    isSuccessProducts: isSuccess,
    dataProducts: data,
    isLoadingProducts: isLoading,
    errorProducts: error,
    refetchProducts: refetch,
  }
}