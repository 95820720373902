import styled from "styled-components";
import { Input } from 'antd';

export const BotaoLogin = styled.button`
  display: flex;
`

export const MainLogoSection = styled.div`
  flex-direction: column;
`

export const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: blue;
  // padding: 200px;
  justify-content: center;
  width: 30%;
  height: 30%;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
`

export const LoginScreen = styled.div`
  display: flex;
  background-color: #f0f2f5;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const LoginInput = styled(Input)`
  width: 60%;
  margin: 20px;
  padding: 10px;
`;