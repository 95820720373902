import React from 'react';
import { ConfigProvider, DatePicker as DatePickerAntd } from 'antd';
import locale from 'antd/locale/pt_BR';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

export const DatePicker = ({
  value,
  onChange,
  name,
  style,
}) => {
  dayjs.locale('pt-br');
  
  return (
    <ConfigProvider locale={locale}>
          <DatePickerAntd
            style={style}
            format={'DD/MM/YYYY'}
            name={name}
            value={value ? dayjs(value): ''}
            onChange={(target) =>   onChange(name, target?.$d)}
          />
        </ConfigProvider>
  )
};