import { useRef } from "react";
import { individualsMutation } from "../use-cases/mutations/individualsMutation";
import { createIndividual } from "../use-cases/mutations/create-individuals";
import { updateIndividual } from "../use-cases/mutations/update-individuals";
import { useAddOrEdit } from "../../Companies/view-model/useAddOrEdit";

export const individualsModel = () => {
  const individualCpfInputRef = useRef();

  const {
    dataIndividualByCpf,
    individualByCpfMutation,
    isLoadingIndividualByCpf,
    isSuccessIndividualByCpf,
  } = individualsMutation({});

  const onSubmitCpfInput = () => {
    individualByCpfMutation({
      cpf: individualCpfInputRef.current.input.value,
    })
  }
  
  const editOnSuccess = () => {
    if (dataIndividualByCpf?.cpf) {
      individualByCpfMutation({
        cpf: dataIndividualByCpf.cpf,
      })
    }
  }

  const {
    contextHolder,
    visibilityModal: visibilityIndividualEditModal,
    setVisibilityModal: setVisibilityIndividualEditModal,
    objectEdit: individualEdit,
    setObjectEdit: setIndividualEdit,
    onConfirmEdit: onConfirmEditIndividual,
  } = useAddOrEdit({
    createMutation: createIndividual,
    editMutation: updateIndividual,
    editOnSuccess,
    label: 'individuo',
  })

  return {
    individualCpfInputRef,
    dataIndividualByCpf,
    isLoadingIndividualByCpf,
    isSuccessIndividualByCpf,
    onSubmitCpfInput,
    contextHolder,
    visibilityIndividualEditModal,
    setVisibilityIndividualEditModal,
    individualEdit,
    setIndividualEdit,
    onConfirmEditIndividual,
  }
}