import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";

async function login({ email, password }) {
  const response = await axios.post(
    `${API}/users/signin`,
    { email, password },
  );
  return response.data;
}

export const loginMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => login(e),
  );

  return {
    isSuccessLoginUseCase: isSuccess,
    dataLoginUseCase: data,
    isLoadingLoginUseCase: isLoading,
    errorLoginUseCase: error,
    loginAdmin: mutate,
  }
}
