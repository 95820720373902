import React from 'react';
import { Button, Popconfirm } from 'antd';
import { RestOutlined } from '@ant-design/icons';

const PopConfirmDelete = ({
  OnCancel,
  OnConfirm,
  OnParams,
}) => (
  <Popconfirm
    title="Excluir"
    description="Deseja realmente excluir?"
    onConfirm={() => OnConfirm(OnParams)}
    onCancel={() => OnCancel(OnParams)}
    okText="Sim"
    cancelText="Não"
  >
    <Button
      type='dashed'
      shape="circle"
      icon={<RestOutlined />}
    />
  </Popconfirm>
);
export default PopConfirmDelete;