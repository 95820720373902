import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function updateProductMutation({ id, product }) {
  const response = await axios.put(
    `${API}/products/${id}`,
    product,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateProduct = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateProductMutation(e),
  );

  return {
    isSuccessUpdateProduct: isSuccess,
    dataUpdateProduct: data,
    isLoadingUpdateProduct: isLoading,
    errorUpdateProduct: error,
    fetchUpdateProduct: mutate,
  }
}
