import React from 'react';
import { Table } from 'antd';
import Flex from '../atoms/Flex';
const TableComponent = ({
  columns,
  data,
}) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      size='small'
    />
  )
};
export default TableComponent;