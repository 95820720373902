import { Button, Input, Typography } from 'antd';
import TableComponent from '../../componentes/molecules/TableComponent';
import { companiesModel } from './view-model/companiesModel';
import { SearchOutlined } from '@ant-design/icons';
import AddOrEditCompanyModal from './components/AddOrEditCompanyModal';

const CompaniesRoute = () => {
  const { Title } = Typography;

  const {
    companyCnpjInput,
    onSubmitCnpjInput,
    dataCompanyByCnpj,
    contextHolder,
    visibilityAddOrEditCompanyModal,
    setVisibilityAddOrEditCompanyModal,
    companyEdit,
    setCompanyEdit,
    onConfirmAddOrEditCompany,
  } = companiesModel();
  console.log({
    dataCompanyByCnpj,
  })
  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '100%' }}>
      {contextHolder}
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', width: '50%', gap: '5%' }}>
        <Title level={2}>Empresas</Title>
        <Button type="default" onClick={() => setVisibilityAddOrEditCompanyModal(true)}>
          Criar Empresa
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          placeholder="CNPJ"
          style={{ alignSelf: 'baseline', width: '30%' }}
          required
          ref={companyCnpjInput}
        />
        <Button
          type="primary"
          onClick={onSubmitCnpjInput}
          icon={<SearchOutlined />}
          style={{ marginLeft: '5px' }}
        />
      </div>
      {dataCompanyByCnpj ? (
        <div
          style={{
            display: 'flex',
            width: '80%',
            flexDirection: "column",
            backgroundColor: '#e1e3ee',
            marginTop: 30,
            padding: 30,
            gap: '10px',
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>id</div>
            <div>{dataCompanyByCnpj.id}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>CNPJ</div>
            <div>{dataCompanyByCnpj.cnpj}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Razão Social</div>
            <div>{dataCompanyByCnpj.name}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Endereço</div>
            <div>{dataCompanyByCnpj.address}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Criado em</div>
            <div>{dataCompanyByCnpj.createdAt}</div>
          </div>
          <Button
            type="default"
            onClick={() => {
              setCompanyEdit(dataCompanyByCnpj)
              setVisibilityAddOrEditCompanyModal(true)
            }
            }
          >
            Editar
          </Button>
        </div>
      ) : <div>Nenhuma empresa encontrada para este CNPJ</div>}

      <AddOrEditCompanyModal
        onConfirm={onConfirmAddOrEditCompany}
        companyEdit={companyEdit}
        setCompanyEdit={setCompanyEdit}
        setVisibility={setVisibilityAddOrEditCompanyModal}
        visibility={visibilityAddOrEditCompanyModal}
      />
    </div>
  )
}

export default CompaniesRoute;