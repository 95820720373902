import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function validateLaboratoriesSheetMudation({ path }) {
  const response = await axios.post(
    `${API}/accredit-network/validate/laboratories`,
    { path },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const validateLaboratoriesSheet = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => validateLaboratoriesSheetMudation(e),
  );

  return {
    isSuccessValidateLaboratoriesSheet: isSuccess,
    dataValidateLaboratoriesSheet: data,
    isLoadingValidateLaboratoriesSheet: isLoading,
    errorValidateLaboratoriesSheet: error,
    fetchValidateLaboratoriesSheet: mutate,
  }
}
