import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function getProviders() {
  const response = await axios.get(
    `${API}/providers`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const providersQuery = () => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ["getProvidersModelQuery"],
    () => getProviders(),
  );

  return {
    isSuccess,
    data,
    isLoading,
    error,
  }
}