import React from 'react';

import {
  Button,
  Input,
  Modal,
  Switch,
  Space,
  Typography,
} from 'antd';
import CashbackVoucher from './CashbackVoucher';
import { DatePicker } from '../../../componentes/molecules/DataPicker';

const VouchertModal = ({
  dataProviders,
  onConfirm,
  voucherEdit,
  setVoucherEdit,
  setVisibility,
  visibility,
}) => {
  const handleCancel = () => {
    setVisibility(false);
  };

  const onChangeInput = (name, value) => {
    setVoucherEdit({
      ...voucherEdit,
      [name]: value,
    })
  }

  const buttonLabel = voucherEdit?.id ? 'EDITAR' : 'SALVAR'
  
  return (
    <Modal
      title="Voucher"
      visible={visibility}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          CANCELAR
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onConfirm}
        >
          {buttonLabel}
        </Button>,
      ]}
      width='70%'
    >
      <div style={{ margin: '2%'}}>
        <Typography.Title level={5}>Código</Typography.Title>
        <Input
          value={voucherEdit?.code || ''}
          name='code'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <Space />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        margin: '2%',
        marginTop: '4%',
      }}
      >
        <div style={{ alignSelf: 'baseline', width: '80%' }}>
          <Typography.Title level={5}>Validade</Typography.Title>
          <DatePicker
            style={{ alignSelf: 'baseline', width: '80%' }}
            name='expirationDate'
            value={voucherEdit?.expirationDate}
            onChange={onChangeInput}
          />
        </div>
        <div>
          <Typography.Title level={5}>Habilitado</Typography.Title>
          <Switch
            checked={!(voucherEdit?.deleted || false)}
            checkedChildren="Sim"
            unCheckedChildren="Não"
            onChange={(value) => onChangeInput('deleted', !value)}
          />
        </div>
      </div>
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Descrição</Typography.Title>
        <Input
          value={voucherEdit?.description || ''}
          name='description'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <Space />
      <div style={{ margin: '2%', width: '100%'}}>
        <CashbackVoucher
          voucherEdit={voucherEdit}
          setVoucherEdit={setVoucherEdit}
          dataProviders={dataProviders}
        >
        </CashbackVoucher>
      </div>
    </Modal>
  );
};

export default VouchertModal;
