import axios from "axios";
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";
import { useMutation } from "react-query";

async function getCompaniesByCnpj({ cnpj }) {
  const response = await axios.get(
    `${API}/companies?cnpj=${cnpj}`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const companiesMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => getCompaniesByCnpj(e),
  );

  return {
    isSuccessCompanyByCnpj: isSuccess,
    dataCompanyByCnpj: data,
    isLoadingCompanyByCnpj: isLoading,
    errorCompanyByCnpjUseCase: error,
    companyByCnpjMutation: mutate,
  }
}