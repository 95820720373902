import { Button, Typography } from 'antd';
import TableComponent from '../../componentes/molecules/TableComponent';
import { voucherModel } from './view-model/voucherModel';
import VouchertModal from './components/VoucherModal';
const VoucherRoute = () => {
  const { Title } = Typography;
  const {
    contextHolder,
    dataProviders,
    dataVouchers,
    onConfirmVoucherEdit,
    setVisibilityVoucherEditModal,
    setVoucherEdit,
    visibilityVoucherEditModal,
    voucherColumns,
    voucherEdit,
  } = voucherModel();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '30px', width: '100%' }}>
      {contextHolder}
      <div style={{ display: 'flex', gap: '30px' }}>
        <Title level={2}>Voucher</Title>
        <Button type="default" onClick={() => {
          setVisibilityVoucherEditModal(true),
          setVoucherEdit({})
        }}>
          Criar Voucher
        </Button>
      </div>
      <TableComponent
        data={dataVouchers}
        columns={voucherColumns}
      />
      <VouchertModal
        visibility={visibilityVoucherEditModal}
        setVisibility={setVisibilityVoucherEditModal}
        voucherEdit={voucherEdit}
        setVoucherEdit={setVoucherEdit}
        onConfirm={onConfirmVoucherEdit}
        dataProviders={dataProviders}
      />
    </div>
  )
}

export default VoucherRoute;
