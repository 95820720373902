import { notification } from "antd";
import { useEffect, useState } from "react";

export const useAddOrEdit = ({
  createMutation,
  createOnsuccess,
  editMutation,
  editOnSuccess,
  label,
}) => {

  const [visibilityModal, setVisibilityModal] = useState(false)
  const [objectEdit, setObjectEdit] = useState({})
  const [api, contextHolder] = notification.useNotification();

  const {
    isSuccess: isSuccessCreate,
    isLoading: isLoadingCreate,
    error: errorCreate,
    mutate: fetchCreate,
  } = createMutation()

  const {
    isSuccess: isSuccessUpdate,
    isLoading: isLoadingUpdate,
    error: errorUpdate,
    mutate: fetchUpdate,
  } = editMutation()
  
  const openNotificationWithIcon = (
    type,
    message,
    description,
  ) => {
    api[type]({
      message,
      description,
    })
  }
  
  useEffect(() => {
    if (isSuccessUpdate && !isLoadingUpdate) {
      setVisibilityModal(false)
      openNotificationWithIcon('success', 'Sucesso', `Sucesso ao atualizar ${label.toLowerCase()}.`)
      editOnSuccess?.()
      setObjectEdit({})
    }

    if (errorUpdate && !isLoadingUpdate) {
      openNotificationWithIcon('error', 'Erro', `Erro ao atualizar ${label.toLowerCase()}`)
    }
  }, [isSuccessUpdate, isLoadingUpdate, errorUpdate])

  useEffect(() => {
    if (isSuccessCreate && !isLoadingCreate) {
      setObjectEdit({})
      setVisibilityModal(false)
      createOnsuccess?.()
      openNotificationWithIcon('success', 'Sucesso', `Sucesso ao atualizar ${label.toLowerCase()}.` )
    }

    if (errorCreate && !isLoadingCreate) {
      openNotificationWithIcon('error', 'Erro', `Erro ao criar ${label.toLowerCase()}`)
    }
  }, [isSuccessCreate, isLoadingCreate, errorCreate])

  const onConfirmEdit = () => {
    if (!objectEdit.id) {
      fetchCreate({
        ...objectEdit,
      })
    } else {
      fetchUpdate({
        id: objectEdit.id,
        update: {
          ...objectEdit,
        },
      })
    }
  }

  return {
    contextHolder,
    visibilityModal,
    setVisibilityModal,
    objectEdit,
    setObjectEdit,
    onConfirmEdit,
  }
}