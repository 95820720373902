import { Button, Modal, Typography } from 'antd';
import TableComponent from '../../componentes/molecules/TableComponent';
import { productsModel } from './viewModel/productsModel';
import UploadModal from '../../componentes/organisms/UploadModal/UploadModal';
import EditProductModal from './components/EditProductModal';
import Flex from '../../componentes/atoms/Flex';
const ProductsRoute = () => {
  const { Title } = Typography;

  const {
    contextHolder,
    dataProducts,
    productColumns,
    visibilityProductsUploadModal,
    setVisibilityProductsUploadModal,
    visibilityProductEditModal,
    setVisibilityProductEditModal,
    productEdit,
    setProductEdit,
    onConfirmEditProduct,
    onSubmitProduct,
  } = productsModel();

  return (
    <Flex
      flexDirection={"column"}
      style={{
        margin: '30px',
        width: '100%',
    }}>
      {contextHolder}
      <Flex style={{ gap: '30px' }}>
        <Title level={2}>Produtos</Title>
        <Button type="default" onClick={() => setVisibilityProductsUploadModal(true)}>
          Atualizar Produtos
        </Button>
      </Flex>
      <UploadModal
        setVisibility={setVisibilityProductsUploadModal}
        visibility={visibilityProductsUploadModal}
        title='Atualizar Planilha de Produtos'
        folderName='products-sheet'
        prefixFileName='products'
        onSubmit={onSubmitProduct}
      />
      <EditProductModal
        visibility={visibilityProductEditModal}
        setVisibility={setVisibilityProductEditModal}
        productEdit={productEdit}
        setProductEdit={setProductEdit}
        onConfirm={onConfirmEditProduct}
      />
      <TableComponent
        data={dataProducts}
        columns={productColumns}
      />
    </Flex>
  )
}

export default ProductsRoute;