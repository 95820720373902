const STORAGE_KEY = 'access_token';

export const setAccessToken = (token) => {
  localStorage.setItem(STORAGE_KEY, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(STORAGE_KEY);
};

export const removeAccessToken = () => {
  localStorage.removeItem(STORAGE_KEY);
};
