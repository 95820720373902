import axios from "axios";
import { useMutation } from 'react-query';
import { getAccessToken } from "../../../../utils/localStorage";
import { API } from "../../../../utils/http";

async function updateCompanyMutation({ id, update }) {
  const response = await axios.put(
    `${API}/companies/${id}`,
    update,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateCompany = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateCompanyMutation(e),
  );

  return {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  }
}
