import { useEffect, useState } from "react";
import { productsQuery } from "../useCases/queries/productsQuery"
import { unionBy } from "lodash";
import getColumnSearchProps from "../../../componentes/molecules/getColumnSearchProps"
import { Button, notification } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { updateProduct } from "../useCases/mutation/update-product";
import { captalize, convertPriceToMoney } from "../../../utils/utils"
import { AccommodationMap, AdherenceMap, CopaymentMap } from "../../../utils/constants";
import { uploadProduct } from "../useCases/mutation/upload-product";

export const productsModel = () => {
  const {
    dataProducts,
    isLoadingProducts,
    isSuccessProducts,
    refetchProducts,
  } = productsQuery({});

  const {
    isSuccessUpdateProduct,
    dataUpdateProduct,
    isLoadingUpdateProduct,
    errorUpdateProduct,
    fetchUpdateProduct,
  } = updateProduct()

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type,
    message,
    description,
  ) => {
    api[type]({
      message,
      description,
    })
  }

  useEffect(() => {
    const nome = dataUpdateProduct?.props?.name || 'Produto'

    if (isSuccessUpdateProduct && !isLoadingUpdateProduct) {  
      setProductEdit({})
      setVisibilityProductEditModal(false)
      openNotificationWithIcon('success', 'Sucesso', `${nome} alterado com sucesso.`)
      refetchProducts({})
    }

    if (errorUpdateProduct && !isLoadingUpdateProduct) {
      openNotificationWithIcon('error', 'Erro', 'Erro ao alterar o produto.')
    }


  }, [isSuccessUpdateProduct, dataUpdateProduct, isLoadingUpdateProduct, errorUpdateProduct])

  const [visibilityProductsUploadModal, setVisibilityProductsUploadModal] = useState(false)
  const [providers, setProviders] = useState([])
  const [visibilityProductEditModal, setVisibilityProductEditModal] = useState(false)
  const [productEdit, setProductEdit] = useState({
    id: '',
    name: '',
    price: '',
    deleted: false,
  });

  const openEditProduct = (product) => {
    setProductEdit(product)
    setVisibilityProductEditModal(true)
  }

  const {
    isSuccessUploadProduct,
    dataUploadProduct,
    isLoadingUploadProduct,
    errorUploadProduct,
    fetchUploadProduct,
  } = uploadProduct()

  useEffect(() => {
    if (isSuccessUploadProduct && !isLoadingUploadProduct) {
      openNotificationWithIcon('success', 'Sucesso', `Planilha de produtos atualizada com sucesso.`)
      refetchProducts({})
    }

    if (errorUploadProduct && !isLoadingUploadProduct) {
      openNotificationWithIcon(
        'warning',
        'Aviso',
        'A atualização da planilha de produtos pode demorar. Atulize a tela em alguns mintutos',
      );
    }
  }, [
    isSuccessUploadProduct,
    dataUploadProduct,
    isLoadingUploadProduct,
    errorUploadProduct,
  ])

  const onSubmitProduct = ({ path, isSuccess }) => {
    if (isSuccess && path) {
      fetchUploadProduct({ path })
    } else {
      openNotificationWithIcon('error', 'Erro', 'Erro ao atualizar produto. Tente novamente.')
    }
  }

  const onConfirmEditProduct = () => {
    const updateProduct = {
      price: productEdit?.price,
      deleted: productEdit?.deleted,
      copayment: productEdit?.copayment,
    }

    fetchUpdateProduct({
      id: productEdit.id,
      product: updateProduct,
    })
  }


  const productColumns = [
    {
      "title": "Nome",
      "dataIndex": "name",
      "key": "name",
      ...getColumnSearchProps('name', "Nome"),
    },
    {
      "title": "Provedor",
      "dataIndex": "provider",
      "key": "provider",
      render: ({ name }) => (<text>{captalize(name)}</text>),
      filters: providers.map(provider => ({
        text: captalize(provider.name),
        value: provider.id,
      })).sort((a, b) => {
        if (a.text > b.text) {
          return 1;
        }
        if (a.text < b.text) {
          return -1;
        }
        return 0;
      }),
      onFilter: (value, record) => record.provider.id.indexOf(value) === 0,
    },
    {
      "title": "Coparticipação",
      "dataIndex": "copayment",
      "key": "copayment",
      render: value => (<text>{CopaymentMap[value] || ''}</text>),
      filters: [
        {
          text: "Sem",
          value: "without",
        },
        {
          text: "Com",
          value: "with",
        },
        {
          text: "Parcial",
          value: "partial",
        },
      ],
      onFilter: (value, record) => record.copayment === value,
    },
    {
      "title": "Reembolso",
      "dataIndex": "reimbursement",
      "key": "reimbursement",
      sorter: (a, b) => a.reimbursement - b.reimbursement,
      render: value => (<text>{convertPriceToMoney(value)}</text>),
    },
    {
      "title": "Idade Ini",
      "dataIndex": "fromAge",
      "key": "fromAge",
      sorter: (a, b) => a.fromAge - b.fromAge,
      ...getColumnSearchProps('fromAge', "Idade Ini"),
    },
    {
      "title": "Idade Fim",
      "dataIndex": "toAge",
      "key": "toAge",
      sorter: (a, b) => a.toAge - b.toAge,
      ...getColumnSearchProps('toAge', "Idade Fim"),
    },
    {
      "title": "Aderência",
      "dataIndex": "adherence",
      "key": "adherence",
      render: value => (<text>{AdherenceMap[value] || ''}</text>),
      filters: [
        {
          text: "Livre Adesão",
          value: "loose",
        },
        {
          text: "Compulsório",
          value: "compulsory",
        },
      ],
      onFilter: (value, record) => record.adherence === value,
    },
    {
      "title": "Hospedagem",
      "dataIndex": "accommodation",
      "key": "accommodation",
      render: value => (<text>{AccommodationMap[value] || ''}</text>),
      filters: [
        {
          text: "Individual",
          value: "private",
        },
        {
          text: "Compartilhado",
          value: "shared",
        },
      ],
      onFilter: (value, record) => record.accommodation === value,
    },
    {
      "title": "Preço",
      "dataIndex": "price",
      "key": "price",
      render: value => (<text>{convertPriceToMoney(value)}</text>),
      sorter: (a, b) => a.price - b.price,
    },
    {
      "title": "Estado",
      "dataIndex": "state",
      "key": "state",
      ...getColumnSearchProps('state', "Estado"),
    },
    {
      "title": "Visível",
      "dataIndex": "deleted",
      "key": "deleted",
      render: value => (<text>{!value ? 'Sim' : 'Não'}</text>),
      onFilter: (value, record) => record.deleted === value,
      filters: [
        {
          text: "Sim",
          value: false,
        },
        {
          text: "Não",
          value: true,
        },
      ],
    },
    {
      "title": "Editar",
      "dataIndex": "id",
      "key": "id",
      render: (_, product) => {
        return (
          <Button
            key={`edit_${product.id}`}
            type='dashed'
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => openEditProduct(product)}
          />);
      },
    },
  ];

  useEffect(() => {
    if (!isLoadingProducts && isSuccessProducts) {
      const allProviders = dataProducts.map(data => data.provider)
      setProviders(unionBy(allProviders, 'id'))
    }
  }, [isLoadingProducts])

  return {
    contextHolder,
    dataProducts,
    isLoadingProducts,
    productColumns,
    visibilityProductsUploadModal,
    setVisibilityProductsUploadModal,
    visibilityProductEditModal,
    setVisibilityProductEditModal,
    productEdit,
    setProductEdit,
    onConfirmEditProduct,
    onSubmitProduct,
  }
}