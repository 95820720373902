import { useRef } from "react";
import { companiesMutation } from "../use-cases/mutations/companiesMutation"
import { useAddOrCreate, useAddOrEdit } from "./useAddOrEdit";
import { createCompany } from "../use-cases/mutations/create-companies";
import { updateCompany } from "../use-cases/mutations/update-company";

export const companiesModel = () => {
  const companyCnpjInput = useRef();
  const {
    dataCompanyByCnpj,
    isLoadingCompanyByCnpj,
    companyByCnpjMutation,
  } = companiesMutation({});

  const onSubmitCnpjInput = () => {
    console.log(companyCnpjInput.current.input.value)
    companyByCnpjMutation({
      cnpj: companyCnpjInput.current.input.value,
    })
  }

  const editOnSuccess = () => {
    companyByCnpjMutation({
      cnpj: dataCompanyByCnpj.cnpj,
    })
  }

  const {
    contextHolder,
    visibilityModal: visibilityAddOrEditCompanyModal,
    setVisibilityModal: setVisibilityAddOrEditCompanyModal,
    objectEdit: companyEdit,
    setObjectEdit: setCompanyEdit,
    onConfirmEdit: onConfirmAddOrEditCompany,
  } = useAddOrEdit({
    createMutation: createCompany,
    editMutation: updateCompany,
    editOnSuccess,
    label: 'empresa',
  })

  return {
    dataCompanyByCnpj,
    isLoadingCompanyByCnpj,
    companyCnpjInput,
    onSubmitCnpjInput,
    contextHolder,
    visibilityAddOrEditCompanyModal,
    setVisibilityAddOrEditCompanyModal,
    companyEdit,
    setCompanyEdit,
    onConfirmAddOrEditCompany,
  }
}