import { useCallback } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import Flex from "../atoms/Flex";
import Typography from "../atoms/Typography/Typography";
import { useDropzone } from 'react-dropzone';

const UploadFile = ({
  title,
  subtitle,
  disclaimer,
  presignedUrl,
  updatedFile,
}) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[acceptedFiles.length - 1];
    updatedFile(file)
  }, [presignedUrl]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Flex
      flexDirection={"column"}
      style={{
        width: "100%",
        cursor: "pointer",
      }}
    >
      <div
        {...getRootProps()}
        style={{
          border: "1px solid rgba(0, 0, 0, 0.4)",
          borderStyle: "dashed",
          borderRadius: "10px",
        }}
      >
        <Flex
          justifyContent={"space-evenly"}
          style={{ margin: 15 }}
          alignItems={"center"}
        >
          <input {...getInputProps()} />
          <FaCloudUploadAlt color="#5900CC"/>
          <Flex
            flexDirection={"column"}    
            gapSize
          >
            <Typography
              variant="subheading2"
              fontWeight={200}
              color={"primary"}
            >
              {title}
            </Typography>
            <Typography
              variant="subheading2"
              fontWeight={200}
              color={"terceary"}
            >
              {subtitle}
            </Typography>
          </Flex>
        </Flex>
      </div>
      <Typography
        variant="subheading2"
        fontWeight={200}
        color={"terceary"}
      >
        {disclaimer}
      </Typography>
    </Flex>
  )
}

export default UploadFile;