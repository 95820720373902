import { Button, Image } from 'antd';
import {
  LoginInput,
  LoginSection,
  LoginScreen,
  MainLogoSection,
} from './components/LoginStyledComponents';
import { Typography } from 'antd';
import { loginModel } from './viewModel/loginModel';

const { Title } = Typography;
const DUCA_LOGO = require('../../assets/duca.png')

const Login = () => {
  const {
    userEmailInput,
    userPasswordInput,
    onChangeEmailInput,
    onChangePasswordInput,
    handleLogin,
  } = loginModel()
  return (
    <LoginScreen>
      <MainLogoSection>
        <Image src={DUCA_LOGO} style={{ maxWidth: 300 }}/>
        <Title level={2}>Bem-vindo à revolução da sua saúde</Title>
      </MainLogoSection>
      <LoginSection>
        <LoginInput
          placeholder="email"
          onChange={(e) => onChangeEmailInput(e.target.value)}
          value={userEmailInput}
        />
        <LoginInput
          placeholder="senha"
          type="password"
          onChange={(e) => onChangePasswordInput(e.target.value)}
          value={userPasswordInput}
        />
        <Button
          type="primary"
          onClick={handleLogin}
        >
          Login
        </Button>
      </LoginSection>
    </LoginScreen>
  )
};

export default Login