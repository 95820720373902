import React from 'react';
import {
  Button,
  Image,
  Modal,
  Typography,
} from 'antd';
import Flex from '../../../componentes/atoms/Flex';
import {
  getAccommodation,
  getAdherence,
  getCopayment,
  getDocumentType,
  getProviderName,
} from '../../../utils/i18n-product';
import {
  cnpjMask,
  convertPriceToMoney,
  cpfMask,
  phoneMask,
} from '../../../utils/utils';

const ContractModal = ({
  onConfirm,
  visibility,
  handleCancel,
  contract,
}) => {
  return (
    <Modal
      title="Contrato"
      open={visibility}
      onCancel={handleCancel}
      width={"90%"}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Fechar
        </Button>,
      ]}
    >
      <Flex flexDirection={"column"}>
        <Flex justifyContent={"space-between"}>
          <Flex
            flexDirection={"column"}
            style={{
              flex: 1,
              margin: 12,
              padding: 12,
              backgroundColor: "#F4F1FC",
              borderRadius: 8,
            }}
          >
            <Typography.Title level={5}>Usuario</Typography.Title>
            <Flex justifyContent={"space-between"}>
              <Flex>email</Flex>
              <Flex>{contract?.user?.email}</Flex>
            </Flex>
            <Flex justifyContent={"space-between"}>
              <Flex>email confirmado</Flex>
              <Flex>{contract?.user?.emailConfirmed ? "Sim": "Não"}</Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection={"column"}
            style={{
              flex: 1,
              margin: 12,
              padding: 12,
              backgroundColor: "#F4F1FC",
              borderRadius: 8,
            }}
          >
            <Typography.Title level={5}>Empresa</Typography.Title>
            <Flex justifyContent={"space-between"}>
              <Flex>cnpj</Flex>
              <Flex>{cnpjMask(contract?.company?.cnpj)}</Flex>
            </Flex>
            {contract?.company?.documents?.map((doc, i) => (
              <Flex key={i} flexDirection={"column"}>
                <Flex>{getDocumentType(doc.type)}</Flex>
                <Flex>
                  <Image
                    width={30}
                    src={doc.bucketLink}
                  />
                </Flex>
                <a
                  download
                  href={doc.bucketLink}
                  target='_blank'
                  rel="noreferrer"
                >
                  download
                </a>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex justifyContent={"space-between"}>
          <Flex
            flexDirection={"column"}
            style={{
              flex: 1,
              margin: 12,
              padding: 12,
              backgroundColor: "#F4F1FC",
              borderRadius: 8,
            }}
          >
            <Typography.Title level={5}>Vidas</Typography.Title>
            {contract?.signers?.map((signer, i) => (
              <Flex key={i} flexDirection={"column"}>
                <Typography.Title level={5}>{signer.type === "holder" ? "Titular": "Dependente"}</Typography.Title>
                <Flex flexDirection={"column"}>
                  <Flex justifyContent={"space-between"}>
                    <Flex>CPF</Flex>
                    <Flex>{cpfMask(signer.cpf)}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Nome</Flex>
                    <Flex>{signer.name}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Email</Flex>
                    <Flex>{signer.email}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Celular</Flex>
                    <Flex>{phoneMask(signer.cellphone)}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Estado civil</Flex>
                    <Flex>{signer.civilStatus}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Profissão</Flex>
                    <Flex>{signer.occupation}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Peso</Flex>
                    <Flex>{signer.weight}</Flex>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Flex>Altura</Flex>
                    <Flex>{signer.height}</Flex>
                  </Flex>
                  <Flex
                    gapSize={"lg"}
                    style={{ flexWrap: "wrap" }}
                  >
                    {signer?.documents?.map((doc, i) => (
                      <Flex
                        key={i}
                        flexDirection={"column"}
                        style={{
                          backgroundColor: "whitesmoke",
                          borderRadius: 12,
                          padding: 8,
                        }}
                      >
                        <Flex>{getDocumentType(doc.type)}</Flex>
                        <Flex>
                          <Image
                            width={30}
                            src={doc.bucketLink}
                          />
                        </Flex>
                        <a
                          download
                          href={doc.bucketLink}
                          target='_blank'
                          rel="noreferrer"
                        >
                          download
                        </a>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
          <Flex
            flexDirection={"column"}
            style={{
              flex: 1,
              margin: 12,
              padding: 12,
              backgroundColor: "#F4F1FC",
              borderRadius: 8,
              height: "100%",
            }}
          >
            <Typography.Title level={5}>Simulação</Typography.Title>
            <Flex flexDirection={"column"} gapSize>
              <Flex justifyContent={"space-between"}>
                <Flex>Código de Cashback</Flex>
                <Flex>{contract?.simulation?.code}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Idade</Flex>
                <Flex>{contract?.simulation?.age}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Dependentes escolhidos</Flex>
                <Flex>{contract?.simulation?.dependents?.map(d => d.age).join(",")}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Estado</Flex>
                <Flex>{contract?.simulation?.selectedProduct?.state}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Seguradora</Flex>
                <Flex>{getProviderName(contract?.simulation?.selectedProduct?.provider?.name)}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Produto Escolhido</Flex>
                <Flex>{contract?.simulation?.selectedProduct?.name}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Preço</Flex>
                <Flex>{convertPriceToMoney(contract?.simulation?.selectedProduct?.price)}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Acomodação</Flex>
                <Flex>{getAccommodation(contract?.simulation?.selectedProduct?.accommodation)}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Aderencia</Flex>
                <Flex>{getAdherence(contract?.simulation?.selectedProduct?.adherence)}</Flex>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Flex>Coparticipação</Flex>
                <Flex>{getCopayment(contract?.simulation?.selectedProduct?.copayment)}</Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ContractModal;