import React from 'react';
import { Modal, Space } from 'antd';
import TableComponent from '../../../componentes/molecules/TableComponent';
import PopConfirmDelete from './PopConfirmDelete';
import getColumnSearchProps from "../../../componentes/molecules/getColumnSearchProps"

const LaboratoriesModal = ({
  visibility,
  setVisibility,
  laboratories = [],
  onConfirm,
}) => {
  const handleCancel = () => {
    setVisibility(false);
  };

  return (
    <Modal
      style={{ minWidth: '50%' }}
      title="Laboratorios"
      open={visibility}
      onCancel={handleCancel}
    >
      <div style={{ margin: '5%' }}>
        <TableComponent
          data={laboratories}
          columns={[
            {
              "title": "Nome",
              "dataIndex": "name",
              "key": "name",
              ...getColumnSearchProps('name', "Nome"),
            },
            {
              "title": "Excluir",
              "dataIndex": "id",
              "key": "id",
              render: (id) => {
                return (
                  <PopConfirmDelete
                    OnCancel={()=> {}}
                    OnConfirm={onConfirm}
                    OnParams={id}
                  />
                );
              },
            },
          ]}
        />
      </div>
      <Space />
    </Modal >
  );
};

export default LaboratoriesModal;