import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function createIndividualMutation(individual) {
  const response = await axios.post(
    `${API}/individuals`,
    individual,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const createIndividual = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => createIndividualMutation(e),
  );

  return {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  }
}
