import React from 'react';
import { convertPriceToMoney } from '../../../utils/utils';
import {
  Button,
  Input,
  Modal,
  Switch,
  Space,
  Typography,
  Select,
} from 'antd';
import { CopaymentMap } from '../../../utils/constants';

const EditProductModal = ({
  onConfirm,
  productEdit,
  setProductEdit,
  setVisibility,
  visibility,
}) => {
  const { Option } = Select;
  const handleCancel = () => {
    setVisibility(false);
  };

  const onChangeInput = (name, value) => {
    setProductEdit({
      ...productEdit,
      [name]: value,
    })
  }

  return (
    <Modal
      title="Editar Produto"
      open={visibility}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onConfirm}
        >
          Salvar
        </Button>,
      ]}
    >
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Nome</Typography.Title>
        <Input
          value={productEdit?.name || ''}
          disabled={true}
        />
      </div>
      <Space />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        margin: '2%',
      }}
      >
        <div style={{ alignSelf: 'baseline', width: '70%' }}>
          <Typography.Title level={5}>Preço</Typography.Title>
          <Input
            style={{ alignSelf: 'baseline', width: '80%' }}
            name='price'
            defaultValue={productEdit?.price || ''}
            value={productEdit?.price || ''}
            onChange={({ target }) => onChangeInput(target.name, target.value)}
          />
        </div>
        <div>
          <Typography.Title level={5}>Habilitado</Typography.Title>
          <Switch
            checked={!(productEdit?.deleted || false)}
            checkedChildren="Sim"
            unCheckedChildren="Não"
            onChange={(value) => onChangeInput('deleted', !value)}
          />
        </div>
      </div>
      <Space />
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Cooparticipação</Typography.Title>
        <Select
          defaultValue="partial"
          value={productEdit?.copayment || ''}
          style={{ width: 120 }}
          onChange={(value) => onChangeInput('copayment', value)}>
          {
            Object.keys(CopaymentMap).map(
              key => (
                <Option key={key} value={key}>
                  {CopaymentMap[key]}
                </Option>))
          }
        </Select>
      </div>
    </Modal>
  );
};

export default EditProductModal;