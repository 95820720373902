import Login from './pages/Login/Login';

import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import SideMenu from './componentes/molecules/SideMenu';
import { getAccessToken } from './utils/localStorage';
import ProductsRoute from './pages/Products/ProductsRoute';
import ContractsRoute from './pages/Contracts/ContractsRoute';
import CompaniesRoute from './pages/Companies/CompaniesRoute';
import IndividualsRoute from './pages/Individuals/IndividualsRoute';
import AccreditNetworkRoute from './pages/AccreditNetwork/AccreditNetworkRoute';
import VoucherRoute from './pages/Voucher/VoucherRoute';

const isAuthenticated = () => {
  return !!getAccessToken();
};

// Define a protected route component
const ProtectedRoute = () => {
  return isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} state={{ from: window.location.pathname }} />
  );
};

const router = createBrowserRouter(
  [
    {
      path: '/login',
      element: <Login/>,
    },
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/",
          element: <SideMenu/>,
          children: [
            {
              path: 'companies',
              element: <CompaniesRoute/>,
            },
            {
              path: 'individuals',
              element: <IndividualsRoute/>,
            },
            {
              path: 'contracts',
              element: <ContractsRoute/>,
            },
            {
              path: 'products',
              element: <ProductsRoute/>,
            },
            {
              path: 'accredit-network',
              element: <AccreditNetworkRoute/>,
            },
            {
              path: 'voucher',
              element: <VoucherRoute/>,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <ContractsRoute />,
    },
  ],
);

const AppRoutes = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default AppRoutes
