import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../http";
import { getAccessToken } from "../localStorage";

async function getPresignedUrlMutation({ path, contentType }) {
  const response = await axios.get(
    `${API}/files/get-upload-link`,
    {
      params: {
        contentType,
        path, 
      },
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const getPresignedUrl = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => getPresignedUrlMutation(e),
  );

  return {
    isSuccessPresignedUrlUseCase: isSuccess,
    dataPresignedUrlUseCase: data,
    isLoadingPresignedUrlUseCase: isLoading,
    errorPresignedUrlUseCase: error,
    fetchGetPresignedUrl: mutate,
  }
}
