import React from 'react';
import {
  Button,
  Input,
  Modal,
  Space,
  Typography,
} from 'antd';

const AddOrEditCompanyModal = ({
  onConfirm,
  companyEdit,
  setCompanyEdit,
  setVisibility,
  visibility,
}) => {
  const handleCancel = () => {
    setVisibility(false);
  };

  const onChangeInput = (name, value) => {
    setCompanyEdit({
      ...companyEdit,
      [name]: value,
    })
  }

  return (
    <Modal
      title="Empresa"
      open={visibility}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onConfirm}
        >
          Salvar
        </Button>,
      ]}
    >
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Nome</Typography.Title>
        <Input
          value={companyEdit?.name || ''}
          name='name'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>CNPJ</Typography.Title>
        <Input
          value={companyEdit?.cnpj || ''}
          name='cnpj'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Endereço</Typography.Title>
        <Input
          value={companyEdit?.address || ''}
          name='address'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <Space />
    </Modal>
  );
};

export default AddOrEditCompanyModal;