import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { loginMutation } from '../useCases/login/mutations/loginMutation';
import { setAccessToken } from '../../../utils/localStorage';

export const loginModel = () => {
  const [userEmailInput, setUserEmailInput] = useState('');
  const [userPasswordInput, setUserPasswordInput] = useState('');
  const navigate = useNavigate()

  const {
    isSuccessLoginUseCase,
    dataLoginUseCase,
    isLoadingLoginUseCase,
    loginAdmin,
  } = loginMutation();

  const handleLogin = () => {
    loginAdmin({
      email: userEmailInput,
      password: userPasswordInput,
    });
  }

  const onChangeEmailInput = (email) => {
    setUserEmailInput(email);
  }

  const onChangePasswordInput = (password) => {
    setUserPasswordInput(password);
  }

  useEffect(() => {
    if (isSuccessLoginUseCase && !isLoadingLoginUseCase) {
      setAccessToken(dataLoginUseCase);
      navigate('/contracts', { replace: true });
    }
  }, [isLoadingLoginUseCase, isSuccessLoginUseCase])

  return {
    userEmailInput,
    userPasswordInput,
    onChangeEmailInput,
    onChangePasswordInput,
    handleLogin,
  }
}