import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function getAccreditNetwork() {
  const response = await axios.get(
    `${API}/accredit-networks`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const accreditNetworkQuery = () => {
  const {
    data,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ["getAccreditNetworkQuery"],
    () => getAccreditNetwork(),
  );

  return {
    isSuccessAccreditNetwork: isSuccess,
    dataAccreditNetwork: data,
    isLoadingAccreditNetwork: isLoading,
    errorAccreditNetwork: error,
  }
}