import React from 'react';
import {
  Button,
  Input,
  Modal,
  Space,
  Typography,
  ConfigProvider,
} from 'antd';
import { DatePicker } from '../../../componentes/molecules/DataPicker';

const AddOrEditIndividualModal = ({
  onConfirm,
  individualEdit,
  setIndividualEdit,
  setVisibility,
  visibility,
}) => {
  const handleCancel = () => {
    setVisibility(false);
  };

  const onChangeInput = (name, value) => {
    setIndividualEdit({
      ...individualEdit,
      [name]: value,
    })
  }

  return (
    <Modal
      title="Individuo"
      open={visibility}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onConfirm}
        >
          Salvar
        </Button>,
      ]}
    >
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Nome</Typography.Title>
        <Input
          value={individualEdit?.name || ''}
          name='name'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>E-mail</Typography.Title>
        <Input
          value={individualEdit?.email || ''}
          name='email'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <Input
          value={individualEdit?.cellphone || ''}
          name='cellphone'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>
      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>CPF</Typography.Title>
        <Input
          value={individualEdit?.cpf || ''}
          name='cpf'
          onChange={({target}) => onChangeInput(target.name, target.value)}
        />
      </div>

      <div style={{ margin: '2%' }}>
        <Typography.Title level={5}>Data de nascimento</Typography.Title>
        <DatePicker
          style={{ alignSelf: 'baseline', width: '100%' }}
          name='birthDate'
          value={individualEdit?.birthDate}
          onChange={onChangeInput}
        />
      </div>
      <Space />
    </Modal>
  );
};

export default AddOrEditIndividualModal;