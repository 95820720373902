import { Button, Input, Typography } from 'antd';
import TableComponent from '../../componentes/molecules/TableComponent';
import { contractsModel } from './viewModel/contractsModel';
import { SearchOutlined } from '@ant-design/icons';
import ContractModal from "./components/ContractModal"
const ContractsRoute = () => {
  const { Title } = Typography;

  const {
    contractColumns,
    contracts,
    contractsCnpjInputRef,
    onSubmitCnpjInput,
    visibilityContractModal,
    closeContractModal,
    contract,
  } = contractsModel();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '100%' }}>
      <Title level={2}>Contratos</Title>
      
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          placeholder="CNPJ"
          style={{ alignSelf: 'baseline', width: '30%' }}
          ref={contractsCnpjInputRef}
        />
        <Button
          type="primary"
          onClick={onSubmitCnpjInput}
          icon={<SearchOutlined />}
          style={{ marginLeft: '5px' }}
        />
      </div>
      <ContractModal
        visibility={visibilityContractModal}
        contract={contract}
        handleCancel={closeContractModal}
        onConfirm={() => {}}
      />
      <TableComponent
        data={contracts}
        columns={contractColumns}
      />
    </div>
  )
}

export default ContractsRoute;