import './App.css';
import 'antd/dist/reset.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './AppRoutes';

const queryClient = new QueryClient()

const App = () => {
  return (
    <div style={{
      display: 'flex',
      height: '100%',
    }}>
      <QueryClientProvider client={queryClient}>
        <AppRoutes/>
      </QueryClientProvider>
    </div>
  )
}

export default App;
