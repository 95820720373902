import { useEffect, useState } from "react";
import { unionBy } from "lodash";
import { Button, notification, message } from "antd";
import { accreditNetworkQuery } from "../use-cases/queries/accreditNetworkQuery";
import { updateAccreditNetwork } from "../use-cases/mutation/update-accredit-network";
import { productsQuery } from "../../Products/useCases/queries/productsQuery";
import { validateHospitalSheet } from "../use-cases/mutation/validate-hospitals-sheet";
import getColumnSearchProps from "../../../componentes/molecules/getColumnSearchProps";
import { validateLaboratoriesSheet } from "../use-cases/mutation/validate-laboratories-sheet";

export const accreditNetworkModel = () => {
  const [visibilityHospitalsModal, setvisibilityHospitalsModal] = useState(false)
  const [hospitals, setHospitals] = useState([]);
  const [visibilityLaboratoriesModal, setvisibilityLaboratoriesModal] = useState(false)
  const [laboratories, setLaboratories] = useState([]);
  const [accreditNetworkIdEdit, setAccreditNetworkIdEdit] = useState('');
  const [tempUpdateAccreditNetwork, setTempUpdateAccreditNetwork] = useState({});
  const [isLabsUploadVisible, setIsLabsUploadVisible] = useState(false)
  const [isHospitalsUploadVisible, setIsHospitalUploadVisible] = useState(false)
  const [productsGroup, setProductsGroup] = useState([]);

  const {
    dataAccreditNetwork,
    errorAccreditNetwork,
  } = accreditNetworkQuery();

  const {
    dataProducts,
    isLoadingProducts,
    isSuccessProducts,
  } = productsQuery({});

  const {
    fetchValidateHospitalSheet,
    errorValidateHospitalSheet,
    isSuccessValidateHospitalSheet,
  } = validateHospitalSheet();

  const {
    fetchValidateLaboratoriesSheet,
    errorValidateLaboratoriesSheet,
    isSuccessValidateLaboratoriesSheet,
  } = validateLaboratoriesSheet();

  const {
    isSuccessUpdateAccreditNetwork,
    dataUpdateAccreditNetwork,
    isLoadingUpdateAccreditNetwork,
    errorUpdateAccreditNetwork,
    fetchUpdateAccreditNetwork,
  } = updateAccreditNetwork()

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type,
    message,
    description,
  ) => {
    api[type]({
      message,
      description,
    })
  }

  const onSubmitHospitals = ({ path, isSuccess }) => {
    if (isSuccess && path) {
      fetchValidateHospitalSheet({ path })
    } else {
      openNotificationWithIcon('error', 'Erro', 'Algo aconteceu, tente novamente mais tarde.')
    }
  }

  const onSubmitLaboratories = ({ path, isSuccess }) => {
    if (isSuccess && path) {
      fetchValidateLaboratoriesSheet({ path })
    } else {
      openNotificationWithIcon('error', 'Erro', 'Algo aconteceu, tente novamente mais tarde.')
    }
  }


  useEffect(() => {
    if (isSuccessUpdateAccreditNetwork && !isLoadingUpdateAccreditNetwork) {
      if (tempUpdateAccreditNetwork?.hospitals) {
        setHospitals(tempUpdateAccreditNetwork.hospitals)
      }

      if (tempUpdateAccreditNetwork?.laboratories) {
        setLaboratories(tempUpdateAccreditNetwork.laboratories)
      }

      setTempUpdateAccreditNetwork({})
      message.success('Excluido com sucesso.');
    }

    if (errorAccreditNetwork && !isLoadingUpdateAccreditNetwork) {
      setTempUpdateAccreditNetwork({})
      message.error('Erro ao excluir!');
    }
  }, [
    isSuccessUpdateAccreditNetwork,
    dataUpdateAccreditNetwork,
    isLoadingUpdateAccreditNetwork,
    errorUpdateAccreditNetwork,
  ])

  const onConfirmDeleteHospitals = (id) => {
    const newHospitals = hospitals.filter(hospital => hospital.id !== id)
    setTempUpdateAccreditNetwork({
      ...tempUpdateAccreditNetwork,
      hospitals: newHospitals,
    })

    const update = {
      hospitals: newHospitals, 
    }

    fetchUpdateAccreditNetwork({
      id: accreditNetworkIdEdit,
      accreditNetworks: update,
    })
  }

  const onConfirmDeleteLaboratories = (id) => {
    const newLaboratories = laboratories.filter(laboratory => laboratory.id !== id)
    setTempUpdateAccreditNetwork({
      ...tempUpdateAccreditNetwork,
      laboratories: newLaboratories,
    })

    const update = {
      laboratories: newLaboratories, 
    }

    fetchUpdateAccreditNetwork({
      id: accreditNetworkIdEdit,
      accreditNetworks: update,
    })
  }

  const openHospitalsModal = (hospitals, id) => {
    setvisibilityHospitalsModal(true)
    setHospitals(hospitals)
    setAccreditNetworkIdEdit(id)
  }
  
  const openLaboratoriesModal = (laboratories, id) => {
    setvisibilityLaboratoriesModal(true)
    setLaboratories(laboratories)
    setAccreditNetworkIdEdit(id)
  }


  useEffect(() => {
    if (!isLoadingProducts && isSuccessProducts) {
      setProductsGroup(unionBy(dataProducts, 'productGroupId'))
    }
  }, [isLoadingProducts])

  const dataTable = dataAccreditNetwork?.map(data => {
    const product = productsGroup?.find(product => data.productGroupId === product.productGroupId)
    
    return {
      id: data.id,
      hospitals: data.hospitals,
      laboratories: data.laboratories,
      productIds: data.productIds,
      provider: data.provider,
      productName: product?.name || "",
      providerName: data.provider.name,
      state: data?.state || "",
    }
  })

  const accreditNetworkColumns = [
    {
      "title": "Produto",
      "dataIndex": "productName",
      "key": "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      ...getColumnSearchProps('productName', "Produto"),
    },
    {
      "title": "Seguradora",
      "dataIndex": "providerName",
      "key": "providerName",
      ...getColumnSearchProps('providerName', "Seguradora"),
    },
    {
      "title": "Estado",
      "dataIndex": "state",
      "key": "state",
      ...getColumnSearchProps('state', "Estado"),
    },
    {
      "title": "Hospitais",
      "dataIndex": "hospitals",
      "key": "hospitals",
      render: (value, row) => (<Button onClick={() => openHospitalsModal(value, row.id)}>Visualizar</Button>),
    },
    {
      "title": "Laboratorios",
      "dataIndex": "laboratories",
      "key": "laboratories",
      render: (value, row) => (<Button onClick={() => openLaboratoriesModal(value, row.id)}>Visualizar</Button>),
    },
  ];

  useEffect(() => {
    if (errorValidateHospitalSheet) {
      openNotificationWithIcon(
        'error',
        'Erro',
        `Não encontrados: ${errorValidateHospitalSheet.response.data.message}`,
      )
    }

    if (isSuccessValidateHospitalSheet) {
      openNotificationWithIcon('success', 'Sucesso', `Processando a planilha...`)
    }
  }, [errorValidateHospitalSheet, isSuccessValidateHospitalSheet])

  useEffect(() => {
    if (errorValidateLaboratoriesSheet) {
      openNotificationWithIcon(
        'error',
        'Erro',
        `Não encontrados: ${errorValidateLaboratoriesSheet.response.data.message}`,
      )
    }
    if (isSuccessValidateLaboratoriesSheet) {
      openNotificationWithIcon('success', 'Sucesso', `Processando a planilha...`)
    }
  }, [errorValidateLaboratoriesSheet, isSuccessValidateLaboratoriesSheet])

  return {
    onConfirmDeleteHospitals,
    visibilityHospitalsModal,
    setvisibilityHospitalsModal,
    hospitals,
    contextHolder,
    dataAccreditNetwork: dataTable,
    accreditNetworkColumns,
    visibilityLaboratoriesModal,
    setvisibilityLaboratoriesModal,
    laboratories,
    onConfirmDeleteLaboratories,
    isLabsUploadVisible,
    setIsLabsUploadVisible,
    isHospitalsUploadVisible,
    setIsHospitalUploadVisible,
    onSubmitHospitals,
    onSubmitLaboratories,
  }
}