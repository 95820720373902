import { Button, Typography } from 'antd';
import TableComponent from '../../componentes/molecules/TableComponent';
import HospitalsModal from './components/HospitalsModal';
import { accreditNetworkModel } from './view-model/accreditNetworkModel'
import LaboratoriesModal from './components/LaboratoriesModal';
import UploadModal from '../../componentes/organisms/UploadModal/UploadModal';
const AccreditNetworkRoute = () => {
  const { Title } = Typography;

  const {
    onConfirmDeleteHospitals,
    visibilityHospitalsModal,
    setvisibilityHospitalsModal,
    hospitals,
    contextHolder,
    dataAccreditNetwork,
    accreditNetworkColumns,
    visibilityLaboratoriesModal,
    setvisibilityLaboratoriesModal,
    laboratories,
    onConfirmDeleteLaboratories,
    isLabsUploadVisible,
    setIsLabsUploadVisible,
    isHospitalsUploadVisible,
    setIsHospitalUploadVisible,
    onSubmitHospitals,
    onSubmitLaboratories,
  } = accreditNetworkModel();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '30px', width: '100%' }}>
      {contextHolder}
      <div style={{ display: 'flex', gap: '30px' }}>
        <Title level={2}>Rede Credenciada</Title>
      </div>
      <div style={{ display: 'flex', gap: 12, marginBottom: 32 }}>
        <Button type="default" onClick={() => setIsHospitalUploadVisible(true)}>
          Upload de hospitais
        </Button>
        <Button type="default" onClick={() => setIsLabsUploadVisible(true)}>
          Upload de laboratórios
        </Button>
      </div>
      <UploadModal
        setVisibility={setIsHospitalUploadVisible}
        visibility={isHospitalsUploadVisible}
        title='Upload de hospitais na rede credenciada'
        folderName='accredit-networks-hospitals-sheet'
        prefixFileName='hospitals'
        onSubmit={onSubmitHospitals}
        disclaimer='*Os produtos devem ter exatamente o mesmo nome correspondente'
      />
      <UploadModal
        setVisibility={setIsLabsUploadVisible}
        visibility={isLabsUploadVisible}
        title='Upload de laboratórios na rede credenciada'
        folderName='accredit-networks-labs-sheet'
        prefixFileName='laboratories'
        onSubmit={onSubmitLaboratories}
        disclaimer='*Os produtos devem ter exatamente o mesmo nome correspondente'
      />
      <LaboratoriesModal
        visibility={visibilityLaboratoriesModal}
        setVisibility={setvisibilityLaboratoriesModal}
        laboratories={laboratories}
        onConfirm={onConfirmDeleteLaboratories}
      />
      <HospitalsModal
        visibility={visibilityHospitalsModal}
        setVisibility={setvisibilityHospitalsModal}
        hospitals={hospitals}
        onConfirm={onConfirmDeleteHospitals}
      />
      <TableComponent
        data={dataAccreditNetwork}
        columns={accreditNetworkColumns}
      />
    </div>
  )
}

export default AccreditNetworkRoute;