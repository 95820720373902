import { Button, Input, Typography } from 'antd';
import TableComponent from '../../componentes/molecules/TableComponent';
import { individualsModel } from './view-model/individualsModel';
import { SearchOutlined } from '@ant-design/icons';
import AddOrEditIndividualModal from './components/AddOrEditIndividualModal';

const IndividualsRoute = () => {
  const { Title } = Typography;

  const {
    dataIndividualByCpf,
    onSubmitCpfInput,
    individualCpfInputRef,
    visibilityIndividualEditModal,
    contextHolder,
    setVisibilityIndividualEditModal,
    individualEdit,
    setIndividualEdit,
    onConfirmEditIndividual,
  } = individualsModel();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '100%' }}>
      {contextHolder}
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', width: '50%', gap: '5%' }}>
        <Title level={2}>Individuos</Title>
        <Button type="default" onClick={() => setVisibilityIndividualEditModal(true)}>
          Criar Individuo
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          placeholder="CPF"
          style={{ alignSelf: 'baseline', width: '30%' }}
          ref={individualCpfInputRef}
        />
        <Button
          type="primary"
          onClick={onSubmitCpfInput}
          icon={<SearchOutlined />}
          style={{ marginLeft: '5px' }}
        />
        <AddOrEditIndividualModal
          visibility={visibilityIndividualEditModal}
          setVisibility={setVisibilityIndividualEditModal}
          individualEdit={individualEdit}
          setIndividualEdit={setIndividualEdit}
          onConfirm={onConfirmEditIndividual}
        />
      </div>
      {dataIndividualByCpf ? (
        <div
          style={{
            display: 'flex',
            width: '80%',
            flexDirection: "column",
            backgroundColor: '#e1e3ee',
            marginTop: 30,
            padding: 30,
            gap: '10px',
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>id</div>
            <div>{dataIndividualByCpf.id}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>CPF</div>
            <div>{dataIndividualByCpf.cpf}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Nome</div>
            <div>{dataIndividualByCpf.name}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Data de nascimento</div>
            <div>{dataIndividualByCpf.birthDate}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Telefone</div>
            <div>{dataIndividualByCpf.cellphone}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Email</div>
            <div>{dataIndividualByCpf.email}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Criado em</div>
            <div>{dataIndividualByCpf.createdAt}</div>
          </div>
          <Button 
            type="default" 
            onClick={() => {
              setIndividualEdit(dataIndividualByCpf)
              setVisibilityIndividualEditModal(true)}
            }
          >
            Editar
          </Button>
        </div>
      ) : <div>Nenhum registro encontrado para este CPF</div>}
    </div>
  )
}

export default IndividualsRoute;