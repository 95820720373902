import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function getContracts({ cnpj }) {
  const response = await axios.get(
    `${API}/contracts?cnpj=${cnpj}`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const contractsQuery = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    refetch,
  } = useQuery(
    "getContractsQuery",
    (e) => getContracts(e),
  );

  return {
    isSuccessContracts: isSuccess,
    dataContracts: data,
    isLoadingContracts: isLoading,
    errorContracts: error,
    refetchContracts: refetch,
  }
}