import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function uploadProductMutation({ path }) {
  const response = await axios.post(
    `${API}/products/upload-product`,
    { path },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const uploadProduct = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => uploadProductMutation(e),
  );

  return {
    isSuccessUploadProduct: isSuccess,
    dataUploadProduct: data,
    isLoadingUploadProduct: isLoading,
    errorUploadProduct: error,
    fetchUploadProduct: mutate,
  }
}
