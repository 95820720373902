import { useEffect, useState } from "react";
import { Button, notification, message, Tag } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { voucherQuery } from "../use-cases/queries/vouchersQuery";
import { providersQuery } from "../use-cases/queries/providersQuery";
import { createVoucher } from "../use-cases/mutation/create-voucher";
import { updateVoucher } from "../use-cases/mutation/update-voucher";
import getColumnSearchProps from "../../../componentes/molecules/getColumnSearchProps";

export const voucherModel = () => {
  const [visibilityVoucherEditModal, setVisibilityVoucherEditModal] = useState(false)
  const [voucherEdit, setVoucherEdit] = useState({});
  const [api, contextHolder] = notification.useNotification();

  const {
    data: dataProviders,
  } = providersQuery();

  const {
    data: dataVouchers,
    refetch: refetchVouchers,
  } = voucherQuery();

  const {
    isSuccess: isSuccessCreateVoucher,
    isLoading: isLoadingCreateVoucher,
    error: errorCreateVoucher,
    mutate: fetchCreateVoucher,
  } = createVoucher()

  const {
    isSuccess: isSuccessUpdateVoucher,
    isLoading: isLoadingUpdateVoucher,
    error: errorUpdateVoucher,
    mutate: fetchUpdateVoucher,
  } = updateVoucher()

  const openNotificationWithIcon = (
    type,
    message,
    description,
  ) => {
    api[type]({
      message,
      description,
    })
  }

  useEffect(() => {
    if (isSuccessUpdateVoucher && !isLoadingUpdateVoucher) {
      setVoucherEdit({})
      setVisibilityVoucherEditModal(false)
      openNotificationWithIcon('success', 'Sucesso', 'Voucher alterado com sucesso.')
      refetchVouchers()
    }

    if (errorUpdateVoucher && !isLoadingUpdateVoucher) {
      openNotificationWithIcon('error', 'Erro', 'Erro ao alterar voucher')
    }
  }, [isSuccessUpdateVoucher, isLoadingUpdateVoucher, errorUpdateVoucher])
  
  useEffect(() => {
    if (isSuccessCreateVoucher && !isLoadingCreateVoucher) {
      setVoucherEdit({})
      setVisibilityVoucherEditModal(false)
      openNotificationWithIcon('success', 'Sucesso', 'Voucher criado com sucesso.')
      refetchVouchers()
    }

    if (errorCreateVoucher && !isLoadingCreateVoucher) {
      openNotificationWithIcon('error', 'Erro', 'Erro ao criar voucher')
    }
  }, [isSuccessCreateVoucher, isLoadingCreateVoucher, errorCreateVoucher])

  const openVoucherEdit = (voucher) => {
    setVoucherEdit(voucher)
    setVisibilityVoucherEditModal(true)
  }

  const onConfirmVoucherEdit = () => {
    const newVoucherCashback = voucherEdit.cashback.map(value => {
      const dataProvider = dataProviders.find(({ id }) => id === value.providerId)
      if(!dataProvider?.id) return null
      return {
        ...value,
        providerName: dataProvider?.name.toUpperCase(),
      }
    }).filter(element => element !== null)

    setVoucherEdit({
      ...voucherEdit,
      cashback: newVoucherCashback,
    })

    if (!voucherEdit.id) {
      fetchCreateVoucher({
        ...voucherEdit,
        cashback: newVoucherCashback,
      })
    } else {
      fetchUpdateVoucher({
        id: voucherEdit.id,
        updateVoucher: {
          ...voucherEdit,
          cashback: newVoucherCashback,
        },
      })
    }
  }
  
  const voucherColumns = [
    {
      "title": "Código",
      "dataIndex": "code",
      "key": "code",
      ...getColumnSearchProps('code', "Código"),
    },
    {
      "title": "Descrição",
      "dataIndex": "description",
      "key": "description",
      render: value => (<text>{value}</text>),
    },
    {
      "title": "Data Validade",
      "dataIndex": "expirationDate",
      "key": "expirationDate",
      render: (value) => (
        <text>{new Date(value).toLocaleDateString('pt-BR')}</text>
      ),
    },
    {
      "title": "Cashback",
      "dataIndex": "cashback",
      "key": "cashback",
      render: (value) => {
        if(!value) return 
        let providersName = value.reduce((acc, provider) => {
          if (provider?.providerName) {
            acc.push(provider?.providerName)
          }
          
          return acc
        },[])

        if(providersName.length > 3) {
          providersName = [...providersName.slice(0, 3), `+${providersName.length - 3}`]
        }

        return providersName.map((name, key) => (
          <Tag key={key} color="purple" style={{margin: '3px'}}>{name}</Tag>
        ))
      },
    },
    {
      "title": "Ativo",
      "dataIndex": "deleted",
      "key": "deleted",
      render: (value) => (<>{value ? 'DESATIVADO' : 'ATIVADO'}</>),
    },
    {
      "title": "Editar",
      "dataIndex": "id",
      "key": "id",
      render: (id, voucher) => {
        return (
          <Button
            key={`edit_${id}`}
            type='dashed'
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => openVoucherEdit(voucher)}
          />);
      },
    },
  ];

  return {
    contextHolder,
    dataProviders,
    dataVouchers,
    onConfirmVoucherEdit,
    setVisibilityVoucherEditModal,
    setVoucherEdit,
    visibilityVoucherEditModal,
    voucherColumns,
    voucherEdit,
  }
}