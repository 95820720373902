import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import UploadFile from '../../molecules/UploadFile';
import { getPresignedUrl } from "../../../utils/aws/get-presigned-url";
import { putObjectToBucket } from '../../../utils/aws/put-object-to-bucket';
import moment from 'moment'

const UploadModal = ({
  setVisibility,
  visibility,
  onSubmit,
  prefixFileName,
  folderName,
  title,
  disclaimer = "",
}) => {
  const [file, setFile] = useState({})
  const [path, setPath] = useState('')

  const {
    dataPresignedUrlUseCase,
    isLoadingPresignedUrlUseCase,
    fetchGetPresignedUrl,
  } = getPresignedUrl();

  const updatedFile = (newFile) => {
    setFile(newFile)
    const splitName = newFile.path.split('.') || []
    const type = splitName[splitName.length - 1]
    const name = `${prefixFileName}-${moment().format()}.${type}`
    setPath(`${folderName}/${name}`)

    fetchGetPresignedUrl({
      path: `${folderName}/${name}`,
      contentType: newFile.type,
    })
  }

  const {
    fetchPutObjectToBucket,
    isLoadingPutObjectToBucketUseCase,
    isSuccessPutObjectToBucketUseCase,
    errorPutObjectToBucketUseCase,
  } = putObjectToBucket();

useEffect(() => {
  if ((errorPutObjectToBucketUseCase || isSuccessPutObjectToBucketUseCase) && !isLoadingPutObjectToBucketUseCase)  {
    const input = {
      path, 
      isSuccess: isSuccessPutObjectToBucketUseCase,
    }

    onSubmit(input)
  }
}, [isLoadingPutObjectToBucketUseCase, isSuccessPutObjectToBucketUseCase])

  const loading = isLoadingPutObjectToBucketUseCase
    || isLoadingPresignedUrlUseCase

  const handleUpload = () => {
    fetchPutObjectToBucket({
      url: dataPresignedUrlUseCase,
      file,
    })

    setVisibility(false);
  }

  return (
    <>
      <Modal
        open={visibility}
        title={title}
        onCancel={() => setVisibility(false)}
        footer={() => (
          <Button
            onClick={handleUpload}
          >
            Atualizar
          </Button>
        )}
      >
        <Spin tip='Loading' size='large' spinning={loading} >
          <UploadFile
            title={'Selecione o arquivo ou arraste e solte aqui'}
            subtitle={'XLSX arquivo de até 5MB.'}
            disclaimer={disclaimer}
            updatedFile={updatedFile}
            presignedUrl={dataPresignedUrlUseCase}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default UploadModal;