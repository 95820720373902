import { useRef, useState } from "react";
import { contractsQuery } from "../useCases/queries/contractsQuery"
import { PlayCircleOutlined } from '@ant-design/icons';
import { convertPriceToMoney } from "../../../utils/utils"
import moment from "moment";
import { Button } from "antd";

export const contractsModel = () => {
  const [visibilityContractModal, setVisibilityContractModal] = useState(false)
  const [contract, setContract] = useState();
  const contractsCnpjInputRef = useRef();

  const {
    dataContracts,
    refetchContracts,
  } = contractsQuery({});

  const openContract = (contractId) => {
    const contractFound = dataContracts.find(c => c.id === contractId)
    setContract(contractFound)
    setVisibilityContractModal(true)
  }

  const closeContractModal = () => {
    setVisibilityContractModal(false)
  }

  const contracts = dataContracts ? dataContracts.map((contract) => {
    const holder = contract.signers?.find(signer => signer.type === "holder");

    const signersCount = contract.simulation?.dependents?.length + 1
    return {
      id: contract.id,
      email: contract?.user?.email || "",
      holderName: holder?.name || "",
      signersCount,
      status: "Conta criada",
      product: contract.simulation?.selectedProduct?.name || "",
      price: convertPriceToMoney(
        Number(String(contract.simulation?.selectedProduct?.price).replace(",", ""))
        || 0,
      ),
      createdAt:  moment(contract.createdAt).format("DD/MM/YYYY"),
    }
  }) : []

  const contractColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Titular',
      dataIndex: 'holderName',
      key: 'holderName',
    },
    {
      title: 'Qtde vidas',
      dataIndex: 'signersCount',
      key: 'signersCount',
    },
    {
      title: 'Plano',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      "title": "Visualizar",
      "dataIndex": "id",
      "key": "id",
      render: (_, contract) => {
        return (
          <Button
            key={`edit_${contract.id}`}
            type='dashed'
            shape="circle"
            icon={<PlayCircleOutlined />}
            onClick={(e) => openContract(contract.id)}
          />
        );
      },
    },
  ]
  
  const onSubmitCnpjInput = () => {
    refetchContracts({
      cnpj: contractsCnpjInputRef.current.input.value,
    })
  }
  console.log({dataContracts})
  return {
    contracts,
    contractColumns,
    contractsCnpjInputRef,
    onSubmitCnpjInput,
    visibilityContractModal,
    contract,
    closeContractModal,
  }
}